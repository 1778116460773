

input[type="text"] {
    box-sizing: border-box;
    border: 1px solid #0D2855;
    border-radius: 30px;
    margin: 1vh;

}

input[type="tel"] {
    box-sizing: border-box;
    border: 1px solid #0D2855;
    border-radius: 30px;
    margin: 1vh;

}

input[type="email"] {
    box-sizing: border-box;
    border: 1px solid #0D2855;
    border-radius: 30px;
    margin: 1vh;

}

.signup-input:hover{
    border: 1px solid #0D2855 !important;
    box-shadow: none;
}

.signup-input:focus{
    border: 1px solid #0D2855 !important;
    box-shadow: none;
}

.checkout {
    box-sizing: border-box;
    width: 12px;
    height: 12px;
    border: 1px solid #0D2855;
    border-radius: 4px;
    margin: 4%;
}


.buttons {
    position: absolute;
    left: 40%;
    right: 37.66%;
    top: 15.48%;
    bottom: 72.72%;

    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 22px;
    /* identical to box height, or 224% */


    /* Color - Grey 2 */

    color: #4F4F4F;
}

.MuiAlert-icon{
    font-size: 17px !important;
}