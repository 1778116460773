.property-specs {
    background: rgba(195, 240, 250, 0.38);
    border-radius: 8px;
}

.property-img-center {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.property-img-overlay {
    background: #000000;
    mix-blend-mode: darken;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    border-radius: 8px 0px 0px 8px;
}

.property-extra-bck{
    background: conic-gradient(from 180deg at 50% 46.92%, rgba(195, 240, 250, 0) 0deg, #E9F9FE 133.12deg, rgba(195, 240, 250, 0) 360deg);
    border-radius: 8px;
    margin-bottom: 32px;
}