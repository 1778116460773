.searchbar-setter{
    position: absolute;
    width: 100%;
    bottom: 0%;
    -ms-transform: translateY(50%);
    transform: translateY(50%);
    z-index: 1;
}

.searchbar-main {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
}

.searchbar-drop {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #092C4C;
}

.searchbar-input::placeholder {
    width: 100%;
    margin-top: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #092C4C;
    opacity: 0.5;
    border: none;
    outline: none;
}

.searchbar-input {
    width: 100%;
    margin-top: 3px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: min(max(13px, 3vw), 16px);
    line-height: 20px;
    color: #092C4C;
    border: none;
    outline: none;
}

.searchbar-text {
    z-index: -1;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    padding-top: 80px;
    border-radius: 16px;
    background-color: rgba(195, 240, 250, 0.38);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.searchbar-text > span{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #092C4C;
}

.searchbar-text-bold{
    font-weight: 600 !important;
    padding-bottom: 2px;
    border-bottom: 2px solid #092C4C;;
}

.searchbar-header{
    z-index: 1;
    width: 80%
}

.search-suggestions{
    width:80%;
    position:absolute;
    top:130%;
    z-index: 20;
    background-color:#FFF;
    border-radius:5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.search-suggestions > p {
    font-family: "Poppins";
    font-style: normal;
    line-height: 26px;
    color: #092C4C;
    font-size: min(max(8.5px, 2.8vw), 13px);
    font-weight: 400;
    padding: min(max(3px, 1vw), 7px) min(max(12px, 4vw), 20px);
    margin: 0px;
}

.search-suggestions > p:hover {
    background-color: #E7F9FE;
    border-radius: 8px;
    cursor: pointer;
}
