.client {
    background-color: #092C4C;
    padding: 24px;
    border-radius: 8px;
}

.client>span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 35px;
    color: #FFFFFF;
}

.text-sec{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
}