.header {
    background-image:
        linear-gradient(180deg, #0D2855 0%, rgba(13, 40, 85, 0) 100%),
        url('../../../public/images/home_bck.png');
    width: 100%;
    height: 60vh;
    background-size: cover;
    color: #FFFFFF;
    padding: 16px 36px;
}

.header-com {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    margin-left: -2px;
}

.header-main-txt{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 78px;
    color: #FFFFFF;

    position: absolute;
    top: 50%;
    left: 7%;
    -ms-transform: translateY(-60%);
    transform: translateY(-50%);
}

.header-items{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
}

.logged-in-header{
    background-color: #0D2855;
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.header-menu-icon{
    display: none;
}

.header-menu-icon > div{
    width: 26px;
    height: 2.5px;
    background-color: #FFFFFF;
    border-radius: 6px;
    margin: 4px auto;
}

.header-menu{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0vh;
    color: #0D2855;
    background-color: #FFFFFF;
    z-index: 2;
    overflow: hidden;
    transition:all 0.8s;
    transition-timing-function: cubic-bezier(.57,.21,.69,3.25);
}

.header-menu p {
    float: right;
    padding-left: 16px;
    padding-right: 16px;
}

.header-menu hr{
    border: 1px solid #30cef2;
    margin: 6px;
    width: 100%;
}


@media screen and (max-width: 1440px) {
    .header-menu-icon .header-menu{
        display: none;
    }
}

@media screen and (max-width: 920px) {
    .header-items{
        display: none;
    }
    .header{
        padding: 16px 16px;
    }
    .header-menu-icon{
        display: block;
    }
}


