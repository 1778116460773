.para_text3 {
    width: 50%;
    height: 10%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
    color: #0D2855;
    margin: 5vh;
    margin-top: 8vh;
}

.chip4 {
    width: 62%;
    height: 24px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0D2855;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.para_text4 {
    width: 45%;
    height: 16%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #0D2855;
    margin-left: 4vh;
    margin-top: -1vh;
}

.para_text5 {

    /* position: absolute; */
    width: 105px;
    height: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;

    /* identical to box height, or 133% */

    color: #0D2855;
}

.icon2 {

    width: 200px;
    height: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #22992E;
    margin: 3vh;
}

.para_text6 {

    width: 346px;
    height: 16px;
    left: 320px;
    top: 540px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
    margin: 5vh;
    margin-top: 7vh;

    /* identical to box height, or 80% */

    color: #0D2855;
}

.para_text7 {

    width: 310px;
    height: 16px;
    margin: 5vh;
    margin-top: -3vh;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;

    /* identical to box height, or 160% */

    color: #0D2855;
}

textarea {
    box-sizing: border-box;
    border: 1px solid #0D2855;
    border-radius: 8px;
    padding: 1vh;
}