.properties{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0px;
}

.property-list-card{
    background-color: white;
    width:60%;
    /* height:100%; */
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 8px;
    margin-left: -20px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 21px;
}

.property-name{
    font-family: Poppins;
    font-weight: 700;
    font-size: 20px;
    line-height: 26.4px;
    color: #092C4C;
}

.property-batch{
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: white;
    background-color: rgba(39, 174, 96, 1);
    border-radius: 12px;
    padding: 3px;
    text-align: center;
}

.property-desc{
    color: rgba(79, 79, 79, 1);
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    margin-top: 3px;

}

.property-detail{
    background-color: rgba(195, 240, 250, 0.38);
    border-radius: 8px;
    margin-left: 12px;
    margin-top: 8px;

}

.property-body{
    color:rgba(79, 79, 79, 1);
    font-family: Poppins;
    font-weight: 400;
    font-size: 16px;

}

.divider{
    height:100%;
    text-align:center;
    background-color:black;
    width:2%;
}

.horizontal-divider{
    text-align: center;
    background-color: gray;
    width: 100%;
    height: 1%;
    margin-top: 1.5%;
}

.info-icon{
    height: 100%;
    width: 28%;
    border-radius: 50%;
    offset: 0px, 4px rgba(0, 0, 0, 0.25);
    box-shadow: rgba(0, 0, 0, 0.15) 0.95px 3.95px 2.6px;
    text-align: center;

}

.info-icon:hover{
    cursor: pointer;
}