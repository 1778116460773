.butto2 {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    width: 98px;
    height: 40px;
    /* identical to box height, or 100% */
    text-align: center;
    margin: 5vh;
    border-radius: 30px;
    color: #FFFFFF;
    background-color: #092C4C;
    ;
}

.para {


    width: 604px;
    height: 16px;
    margin: 5vh;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    font-size: 20px;
    line-height: 16px;

    /* identical to box height, or 80% */

    color: #0D2855;
}

.chip3{
    /* color: #0D2855; */
    color: red;
}