.row{
  margin:0px;
}

.top {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: #0D2855;
}

.f-bold {
  font-weight: 700;
}

.subtext {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #092C4C;
}

.btn-alert {
  background: #F2994A;
  border-radius: 30px;
  color: "white";
  border: none;
  padding: 10px 24px;
}

.btn-info {
  background: #092C4C;
  border-radius: 30px;
  color: "white";
  border: none;
  padding: 8px 24px;
  font-weight: 700;
  font-family: 'Poppins';
}

.btn-white {
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  background: #FFFFFF;
  border-radius: 30px;
  color: #0D2855;
  border: none;
  padding: 8px 24px;
  font-weight: 500;
  font-family: 'Poppins';
}

.btn-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
}
.fluid{
  width: 100%;
  height: auto;
}

.remove-scrollbar::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
.remove-scrollbar{
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}


@media only screen and (max-width: 400px) {
  .top{
    font-size: 28px;
  }
}
