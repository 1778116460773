.slick-dots.slick-thumb {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.slick-dots.slick-thumb li {
    width: 6px;
    height: 6px;
    margin: 0 1.5px;
    border-radius: 999px;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background-color: rgba(13, 40, 85, 0.5);
}

.slick-dots.slick-thumb li.slick-active {
    background-color: #0D2855;
    width: 36px;
}


.carousel-item{
    padding: 10px;
}