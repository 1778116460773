.a_style1 {
    color: #0D2855;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 26.4px;
    margin-top: 1.5vh;
    padding: 1vh;
    
   
}