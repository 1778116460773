.listedPara {
    color: #0D2855;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-top: 3vh;
    margin-left: 2vh;
}

.text1 {
    color: #0D2855;
    font-family: Helvetica Neue;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}


.text2 {
    margin-left: 158vh;
    margin-top: 5vh;
    color: #0D2855;
    font-family: Helvetica Neue;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

}

.button2 {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border-radius: 40px;
    background: #092C4C;
    width: 120px;
    height: 40px;
    flex-shrink: 0;
    line-height: 16px;
    margin-left: 152vh;
    margin-top: 2vh;
}