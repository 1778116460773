.properties-filter{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 15px 0;
    margin-top: 15px;
    background-color: #E8F9FD;
}

.sortby-filters{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 0;
    margin-top: 15px;
    margin-right: 15px;
}