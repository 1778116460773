.login {
    background-color: grey;
    /* position: relative; */
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

}


.login-form {
    /* position: absolute; */
    width: 280px;
    padding: 10px 0px;
    /* height: 440px; */
    /* left: 500px; */
    /* top: 196px; */

    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

}


.info-paragraph {
    width: 50%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: auto;
    /* or 143% */

    text-align: center;
    text-decoration-line: underline;

    /* Color - Primary */

    color: #092C4C;
}


.continue-text {
    margin-top: 42px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #092C4C;
}


.btn1 {
    background: #092C4C;
    border-radius: 40px;
    color: #FFFFFF;
    width: 80%;
    padding: 0.5vh;
    margin-top: 2vh;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.icon_div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 3vh;
}

.icons {
    align-items: center;
    text-align: center;
    /* padding: auto; */
}


.back-btn2 {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #4F4F4F;
    padding:0;
    margin:0
}