.footer {
    background-color: #092C4C;
    padding: 48px 96px 36px 96px;
}


.footer-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}

.footer-items {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #E8F9FD;
}


.footer-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}

.footer-barcode{
    margin-top: 12px;
    width: 60%;
    height: auto;
}

@media only screen and (max-width: 1120px) {
    .footer-barcode {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .footer {
        padding: 24px 24px 36px 24px;
    }
    .footer-barcode {
        width: 35%;
    }
}

@media only screen and (max-width: 576px) {
    .footer-barcode {
        width: 45%;
    }
}
