.btn {
    border: none;
}

.city {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    display: inline-block;
    padding-top: 8px;
}

.expert-btn-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: min(max(12px, 3vw), 16px);
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
}

.expert-shadow{
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 4px 4px 10px #c2c0c0;
}

.btn-shadow{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}